<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBoxIcon"
    :aria-labelledby="name"
  >
    <title :id="name" lang="en">{{ name }}</title>
    <g
      style="stroke: var(--th-blue-dark)"
      :style="{
        stroke: !iconStroke ? 'var(--th-blue-dark)' : iconStroke,
        fill: !iconFill ? 'none' : iconFill
      }"
      v-html="innerIcon"
    >
      <slot />
    </g>
  </svg>
</template>
<script>
import icons from "@/assets/icons/icons";
export default {
  name: "AIcon",
  icons,
  props: {
    viewBox: { type: String, default: "0 0 16 16" },
    name: { type: String, default: "" },
    width: { type: [Number, String], default: 16 },
    height: { type: [Number, String], default: 16 },
    iconStroke: String,
    iconFill: String
  },
  data() {
    return {
      viewBoxIcon: this.viewBox,
      innerIcon: ""
    };
  },
  mounted() {
    let content = Object.keys(this.$options.icons).find(
      i => this.toKebabCase(i) === this.toKebabCase(this.name)
    );
    if (content) {
      let found = this.$options.icons[content];
      this.viewBoxIcon = found[0];
      this.innerIcon = found[1];
    }
  },
  methods: {
    toKebabCase(str) {
      return str.replace(/([a-z])([A-Z0-9])/g, "$1-$2").toLowerCase();
    }
  }
};
</script>
