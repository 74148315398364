export default [
  "0 0 243 78", // width="243" height="78"
  '<path d="M23.8963 46.249C23.8221 46.3233 23.7478 46.3976 23.7478 46.3976C23.7478 46.3233 23.8221 46.3233 23.8963 46.249Z" fill="#F5A22D"/>' +
    '<path d="M59.2585 0H17.9773C8.0639 0 0 8.06389 0 17.9773V59.2585C0 69.172 8.0639 77.2358 17.9773 77.2358H59.2585C67.1745 77.2358 73.8327 72.1312 76.2741 65.029C76.866 63.2535 77.2358 61.256 77.2358 59.2585V17.9773C77.2358 7.98991 69.172 0 59.2585 0Z" fill="#5F7A91"/>' +
    '<path d="M59.4065 40.3936C58.5927 39.7277 57.705 39.2099 56.8172 38.618L56.8912 38.5441C56.9652 38.4701 57.0391 38.4701 57.1871 38.3961C59.9984 36.9165 62.0698 34.697 63.3275 31.7378C64.3632 29.2965 64.5852 26.7071 64.0673 24.1178C63.6974 22.0464 62.8096 20.1228 61.404 18.4213C58.7407 15.2401 55.4116 13.5386 51.2686 13.4646C49.1972 13.4646 22.2682 13.4646 22.2682 13.4646C18.0513 13.4646 14.6482 16.8677 14.6482 21.0846V56.2254C14.6482 60.3684 18.0513 63.7715 22.1942 63.7715C22.1942 63.7715 47.5696 63.8455 51.2686 63.7715C53.4881 63.7715 55.6335 63.2536 57.631 62.1439C63.1055 59.0367 65.5469 52.9703 63.8454 46.8299C62.9576 44.2406 61.478 42.0211 59.4065 40.3936Z" fill="white"/>' +
    '<path d="M51.5649 22.3424C51.6389 22.3424 51.7129 22.3424 51.7869 22.3424C50.5292 22.2684 49.1975 22.7123 48.1618 23.674L38.9882 32.3298H27.2992C24.5619 32.3298 22.3425 30.1104 22.3425 27.3731C22.3425 25.9674 22.9344 24.7838 23.8221 23.822C24.7099 22.8603 25.9676 22.3424 27.3732 22.3424H51.5649Z" fill="#F5A22D"/>' +
    '<path d="M56.5215 27.373C56.5215 28.1129 56.3735 28.8527 56.0036 29.5185C55.7817 30.0363 55.4118 30.4802 55.0419 30.8501C54.1541 31.7379 52.8964 32.3298 51.4908 32.3298H38.8401L48.0137 23.674C49.1974 22.5643 50.751 22.1944 52.1566 22.3424C52.7485 22.4163 53.2663 22.6383 53.7842 22.9342C53.9321 23.0082 54.0801 23.0822 54.2281 23.2301C54.3021 23.3041 54.376 23.3041 54.45 23.3781C54.524 23.4521 54.598 23.4521 54.672 23.526C54.7459 23.6 54.7459 23.6 54.8199 23.674C54.8939 23.748 54.9679 23.822 55.0419 23.8959L55.2638 24.1919C55.3378 24.2659 55.3378 24.2659 55.4118 24.3398L55.5597 24.5618C55.6337 24.6358 55.6337 24.7097 55.7077 24.7837C55.7817 24.9317 55.8557 25.0057 55.9296 25.1536L56.0036 25.2276C56.0776 25.3756 56.1516 25.5235 56.1516 25.6715C56.1516 25.7455 56.2256 25.8195 56.2256 25.8934C56.2995 26.1894 56.3735 26.5592 56.4475 26.8552C56.5215 27.0031 56.5215 27.1511 56.5215 27.373Z" fill="#F35630"/>' +
    '<path d="M39.876 45.1282L30.7024 53.7099C28.7049 55.5595 25.5237 55.4855 23.6742 53.488C22.7864 52.5262 22.3425 51.2686 22.3425 50.0849C22.3425 48.8272 22.7864 47.5695 23.7482 46.6078C23.8221 46.5338 23.8961 46.4598 23.8961 46.4598C24.7839 45.646 25.9676 45.2022 27.2253 45.2022H39.876V45.1282Z" fill="#F35630"/>' +
    '<path d="M51.5648 45.1282H39.8758L30.7022 53.7099C29.5925 54.7457 28.1129 55.1895 26.7073 55.0416C26.8552 55.0416 27.0032 55.1156 27.2251 55.1156L51.5648 55.1896C52.9704 55.1896 54.1541 54.5977 55.1159 53.7099C56.0776 52.8222 56.5955 51.5645 56.5955 50.1589C56.5215 47.3476 54.3021 45.1282 51.5648 45.1282Z" fill="#F5A22D"/>' +
    '<path d="M51.5645 32.3297C52.9702 32.3297 54.1539 31.8118 55.0416 30.8501L54.9676 30.9241L39.8756 45.0544H27.2249C25.9672 45.0544 24.7835 45.5723 23.8958 46.3121L38.9138 32.1817H51.5645V32.3297Z" fill="#F5A22D"/>' +
    '<path d="M27.225 32.3294C29.9625 32.3294 32.1817 30.1102 32.1817 27.3727C32.1817 24.6352 29.9625 22.416 27.225 22.416C24.4875 22.416 22.2683 24.6352 22.2683 27.3727C22.2683 30.1102 24.4875 32.3294 27.225 32.3294Z" fill="#F35630"/>' +
    '<path d="M51.5646 55.1896C54.343 55.1896 56.5953 52.9372 56.5953 50.1589C56.5953 47.3805 54.343 45.1282 51.5646 45.1282C48.7863 45.1282 46.5339 47.3805 46.5339 50.1589C46.5339 52.9372 48.7863 55.1896 51.5646 55.1896Z" fill="#F35630"/>' +
    '<path d="M110.305 48.0146V52.8941H91.1128V48.0146L102.462 32.834H91.4381V27.9545H109.98V32.834L98.6308 48.0146H110.305Z" fill="white"/>' +
    '<path d="M126.668 27.9545C127.222 27.9545 127.704 28.1111 128.114 28.4243C128.548 28.7376 128.837 29.1713 128.981 29.7255L135.813 52.8941H128.873L127.391 46.9664H119.728L118.21 52.8941H111.271L118.102 29.7255C118.246 29.1713 118.524 28.7376 118.933 28.4243C119.367 28.1111 119.861 27.9545 120.415 27.9545H126.668ZM122.584 35.6894L120.957 42.0869H126.126L124.499 35.6894C124.403 35.2797 124.307 34.846 124.21 34.3882C124.138 33.9062 124.078 33.4605 124.03 33.0508H123.054C122.981 33.4605 122.909 33.9062 122.837 34.3882C122.765 34.846 122.68 35.2797 122.584 35.6894Z" fill="white"/>' +
    '<path d="M159.883 27.9545V50.5085C159.883 52.0989 159.1 52.8941 157.533 52.8941H154.497C153.774 52.8941 153.232 52.7254 152.871 52.3881C152.533 52.0507 152.184 51.5567 151.823 50.9061L145.714 40.2797C145.401 39.7014 145.1 39.0628 144.811 38.364C144.546 37.6412 144.317 36.9303 144.124 36.2315H143.799C143.895 36.9303 143.967 37.6532 144.015 38.4002C144.088 39.1231 144.124 39.8219 144.124 40.4966V52.8941H137.799V30.34C137.799 28.7496 138.594 27.9545 140.184 27.9545H143.184C143.883 27.9545 144.401 28.1231 144.738 28.4605C145.1 28.7978 145.473 29.2918 145.859 29.9424L151.714 40.1713C152.052 40.7496 152.377 41.4002 152.69 42.1231C153.003 42.8218 153.305 43.5447 153.594 44.2917H153.919C153.847 43.5447 153.786 42.8218 153.738 42.1231C153.69 41.4002 153.666 40.6652 153.666 39.9182L153.63 27.9545H159.883Z" fill="white"/>' +
    '<path d="M167.346 46.7495C168.213 46.7495 168.803 46.9182 169.117 47.2556C169.454 47.5688 169.623 48.1592 169.623 49.0266V50.617C169.623 51.4844 169.454 52.0869 169.117 52.4242C168.803 52.7374 168.213 52.8941 167.346 52.8941H165.827C164.96 52.8941 164.358 52.7374 164.02 52.4242C163.707 52.0869 163.55 51.4844 163.55 50.617V49.0266C163.55 48.1592 163.707 47.5688 164.02 47.2556C164.358 46.9182 164.96 46.7495 165.827 46.7495H167.346Z" fill="white"/>' +
    '<path d="M183.464 27.6653C185.729 27.6653 187.524 27.8581 188.85 28.2436C190.199 28.6051 191.163 29.2557 191.741 30.1954C192.32 31.1352 192.609 32.4605 192.609 34.1713C192.609 35.9544 192.259 37.3159 191.561 38.2556C190.886 39.1713 189.741 39.7014 188.127 39.846V40.0628C190.103 40.2315 191.476 40.8821 192.247 42.0146C193.042 43.123 193.44 44.6773 193.44 46.6772C193.44 48.3881 193.127 49.7134 192.5 50.6531C191.898 51.5929 190.946 52.2555 189.645 52.6411C188.368 53.0025 186.705 53.1832 184.657 53.1832C182.946 53.1832 181.452 53.1712 180.175 53.1471C178.898 53.1471 177.717 53.123 176.633 53.0748C175.549 53.0266 174.44 52.9664 173.308 52.8941V27.9545C174.32 27.8581 175.32 27.7978 176.308 27.7737C177.296 27.7255 178.356 27.7015 179.488 27.7015C180.645 27.6774 181.97 27.6653 183.464 27.6653ZM183.139 32.4002C182.537 32.4002 181.97 32.4002 181.44 32.4002C180.91 32.3761 180.404 32.3641 179.922 32.3641V37.9303H183.283C184.392 37.9303 185.139 37.7496 185.524 37.3881C185.934 37.0026 186.139 36.2436 186.139 35.111C186.139 34.0026 185.934 33.2797 185.524 32.9424C185.115 32.5809 184.32 32.4002 183.139 32.4002ZM183.356 42.0146H179.922V48.4483C181.006 48.4483 182.175 48.4483 183.428 48.4483C184.729 48.4483 185.597 48.2555 186.03 47.87C186.464 47.4604 186.681 46.617 186.681 45.3399C186.681 43.9905 186.452 43.111 185.994 42.7014C185.561 42.2676 184.681 42.0387 183.356 42.0146Z" fill="white"/>' +
    '<path d="M209.899 27.9545C210.453 27.9545 210.935 28.1111 211.345 28.4243C211.779 28.7376 212.068 29.1713 212.212 29.7255L219.044 52.8941H212.104L210.622 46.9664H202.959L201.441 52.8941H194.502L201.333 29.7255C201.477 29.1713 201.754 28.7376 202.164 28.4243C202.598 28.1111 203.092 27.9545 203.646 27.9545H209.899ZM205.815 35.6894L204.188 42.0869H209.357L207.73 35.6894C207.634 35.2797 207.538 34.846 207.441 34.3882C207.369 33.9062 207.309 33.4605 207.26 33.0508H206.285C206.212 33.4605 206.14 33.9062 206.068 34.3882C205.995 34.846 205.911 35.2797 205.815 35.6894Z" fill="white"/>' +
    '<path d="M231.15 27.593C233.487 27.593 235.391 27.8219 236.861 28.2798C238.331 28.7135 239.403 29.4846 240.078 30.593C240.776 31.6773 241.126 33.1833 241.126 35.111C241.126 36.7255 240.764 38.0869 240.041 39.1954C239.343 40.2797 238.054 40.9303 236.174 41.1472V41.364C236.632 41.4363 237.114 41.5929 237.62 41.8339C238.15 42.0749 238.632 42.4604 239.066 42.9905C239.499 43.5206 239.825 44.2556 240.041 45.1953L242.102 52.8941H234.981L233.21 45.4122C233.017 44.6652 232.74 44.1592 232.379 43.8941C232.042 43.6291 231.523 43.4965 230.825 43.4965C230.222 43.4965 229.656 43.4965 229.126 43.4965C228.62 43.4724 228.138 43.4604 227.68 43.4604V52.8941H221.03V27.9545C222.09 27.8581 223.09 27.7858 224.029 27.7376C224.969 27.6653 225.993 27.6292 227.102 27.6292C228.234 27.6051 229.584 27.593 231.15 27.593ZM231.005 32.5087C229.801 32.5087 228.692 32.5087 227.68 32.5087V38.5809H231.005C232.282 38.5809 233.15 38.3761 233.608 37.9665C234.066 37.5568 234.294 36.7496 234.294 35.5448C234.294 34.3159 234.066 33.5086 233.608 33.1231C233.15 32.7135 232.282 32.5087 231.005 32.5087Z" fill="white"/>'
];
