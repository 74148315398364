// width="313" height="300"
export default [
  "0 0 313 300",
  '<g clip-path="url(#clip0)">\n' +
    '    <path d="M86.4363 84.0512C50.027 105.813 4.29352 104.021 2.65615 161.371C1.51887 201.244 30.5157 223.708 62.6743 237.962C93.4133 251.596 128.887 266.327 159.392 247.788C180.057 235.234 187.413 216.937 213.861 213.564C230.573 211.43 243.309 221.934 260.602 212.928C335.244 174.076 293.471 75.9326 245.987 38.5451C218.926 17.2358 168.74 -6.77696 133.484 4.82347C92.3728 18.3573 118.974 64.5749 86.4363 84.0512Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.9" d="M86.4363 84.0512C50.027 105.813 4.29352 104.021 2.65615 161.371C1.51887 201.244 30.5157 223.708 62.6743 237.962C93.4133 251.596 128.887 266.327 159.392 247.788C180.057 235.234 187.413 216.937 213.861 213.564C230.573 211.43 243.309 221.934 260.602 212.928C335.244 174.076 293.471 75.9326 245.987 38.5451C218.926 17.2358 168.74 -6.77696 133.484 4.82347C92.3728 18.3573 118.974 64.5749 86.4363 84.0512Z" fill="white"/>\n' +
    '    <path d="M199.703 109.858C197.976 109.876 196.251 109.73 194.551 109.424L192.611 108.99L194.363 108.063C200.152 105.039 203.538 101.652 204.494 97.9326C204.98 95.9689 204.684 93.8932 203.669 92.1435H203.35V91.8395C192.51 86.0504 186.301 77.4536 186.301 68.2634C186.301 51.4606 207.2 37.7838 232.889 37.7838C258.578 37.7838 279.491 51.4606 279.491 68.2634C279.491 85.0663 258.506 98.8299 232.817 98.8299C229.109 98.8377 225.406 98.5473 221.745 97.9615C215.826 108.136 205.796 109.858 199.703 109.858ZM196.939 108.295C201.845 108.744 214.19 108.585 220.732 96.7892L220.978 96.3406L221.484 96.4129C225.229 97.0458 229.02 97.3605 232.817 97.3537C257.71 97.3537 277.972 84.3282 277.972 68.3213C277.972 52.3145 257.71 39.289 232.817 39.289C207.924 39.289 187.676 52.3145 187.676 68.3213C187.676 77.005 193.697 85.1965 204.204 90.7541H204.392L204.494 90.8988L204.725 91.0146V91.2317C205.373 92.2804 205.805 93.4477 205.996 94.6653C206.187 95.883 206.134 97.1265 205.839 98.3233C204.942 101.97 201.961 105.314 196.939 108.295Z" fill="#7C98B0"/>\n' +
    '    <path d="M213.102 57.2906H203.875L224.451 85.5343L263.053 34.0274H258.715L223.153 70.5772L213.102 57.2906Z" fill="#7C98B0"/>\n' +
    '    <path d="M156.762 299.822C243.134 299.822 313.151 295.58 313.151 290.348C313.151 285.115 243.134 280.873 156.762 280.873C70.3911 280.873 0.373291 285.115 0.373291 290.348C0.373291 295.58 70.3911 299.822 156.762 299.822Z" fill="#F5F5F5"/>\n' +
    '    <path d="M195.519 202.525C195.076 202.525 194.624 202.525 194.164 202.566C184.582 203.278 176.831 211.698 176.218 222.059C176.082 224.347 176.291 226.643 176.839 228.864C178.954 237.776 179.973 246.93 179.871 256.107V282.891C179.854 283.799 180.183 284.679 180.788 285.337C181.392 285.995 182.222 286.38 183.098 286.406H247.1V202.525H195.519Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.3" d="M195.519 202.525C195.076 202.525 194.624 202.525 194.164 202.566C184.582 203.278 176.831 211.698 176.218 222.059C176.082 224.347 176.291 226.643 176.839 228.864C178.954 237.776 179.973 246.93 179.871 256.107V282.891C179.854 283.799 180.183 284.679 180.788 285.337C181.392 285.995 182.222 286.38 183.098 286.406H247.1V202.525H195.519Z" fill="black"/>\n' +
    '    <path d="M266.459 223.432C266.459 211.405 257.062 201.737 245.762 202.574C236.179 203.286 228.428 211.706 227.815 222.068C227.679 224.355 227.889 226.651 228.436 228.872C230.551 237.784 231.57 246.938 231.469 256.116V282.899C231.452 283.808 231.781 284.687 232.385 285.345C232.989 286.004 233.82 286.388 234.695 286.414H259.498C260.374 286.39 261.206 286.006 261.81 285.348C262.415 284.689 262.744 283.808 262.724 282.899V254.542C262.671 245.943 263.695 237.373 265.773 229.048C266.235 227.214 266.465 225.327 266.459 223.432Z" fill="#7C98B0"/>\n' +
    '    <path d="M220.467 235.459H138.873V286.364H220.467V235.459Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.3" d="M220.467 235.459H138.873V286.364H220.467V235.459Z" fill="black"/>\n' +
    '    <path d="M95.5591 174.486H185.655C189.617 174.486 193.416 176.119 196.218 179.026C199.019 181.933 200.593 185.875 200.593 189.986V246.967H80.613V189.986C80.613 187.95 80.9997 185.934 81.7509 184.053C82.5021 182.171 83.6031 180.462 84.9911 179.023C86.3791 177.583 88.0268 176.442 89.8401 175.663C91.6534 174.885 93.5967 174.485 95.5591 174.486Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.4" d="M95.5591 174.486H185.655C189.617 174.486 193.416 176.119 196.218 179.026C199.019 181.933 200.593 185.875 200.593 189.986V246.967H80.613V189.986C80.613 187.95 80.9997 185.934 81.7509 184.053C82.5021 182.171 83.6031 180.462 84.9911 179.023C86.3791 177.583 88.0268 176.442 89.8401 175.663C91.6534 174.885 93.5967 174.485 95.5591 174.486Z" fill="black"/>\n' +
    '    <path d="M113.271 174.486H190.284C194.056 174.486 197.672 176.04 200.339 178.808C203.006 181.575 204.504 185.328 204.504 189.242V246.993H99.051V189.242C99.051 187.304 99.4188 185.385 100.133 183.595C100.848 181.804 101.896 180.178 103.216 178.808C104.536 177.437 106.104 176.35 107.829 175.609C109.555 174.867 111.404 174.486 113.271 174.486Z" fill="#7C98B0"/>\n' +
    '    <path d="M143.534 230.162H219.41C220.578 230.162 221.698 230.643 222.524 231.5C223.35 232.357 223.814 233.519 223.814 234.731V246.976C223.814 248.188 223.35 249.351 222.524 250.208C221.698 251.065 220.578 251.546 219.41 251.546H143.534C143.186 251.546 142.851 251.403 142.605 251.147C142.358 250.891 142.22 250.544 142.22 250.182V231.559C142.215 231.377 142.246 231.196 142.31 231.027C142.374 230.858 142.471 230.703 142.593 230.573C142.716 230.443 142.862 230.339 143.024 230.269C143.185 230.198 143.359 230.162 143.534 230.162Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.4" d="M143.534 230.162H219.41C220.578 230.162 221.698 230.643 222.524 231.5C223.35 232.357 223.814 233.519 223.814 234.731V246.976C223.814 248.188 223.35 249.351 222.524 250.208C221.698 251.065 220.578 251.546 219.41 251.546H143.534C143.186 251.546 142.851 251.403 142.605 251.147C142.358 250.891 142.22 250.544 142.22 250.182V231.559C142.215 231.377 142.246 231.196 142.31 231.027C142.374 230.858 142.471 230.703 142.593 230.573C142.716 230.443 142.862 230.339 143.024 230.269C143.185 230.198 143.359 230.162 143.534 230.162Z" fill="black"/>\n' +
    '    <path d="M72.6922 286.373H248.101V286.423C248.101 287.089 247.846 287.727 247.392 288.198C246.938 288.669 246.323 288.934 245.681 288.934H75.0958C74.454 288.934 73.8385 288.669 73.3848 288.198C72.931 287.727 72.676 287.089 72.676 286.423V286.373H72.6922Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.4" d="M72.6922 286.373H248.101V286.423C248.101 287.089 247.846 287.727 247.392 288.198C246.938 288.669 246.323 288.934 245.681 288.934H75.0958C74.454 288.934 73.8385 288.669 73.3848 288.198C72.931 287.727 72.676 287.089 72.676 286.423V286.373H72.6922Z" fill="black"/>\n' +
    '    <path d="M177.508 166.058L177.831 167.422L178.202 168.903C178.46 169.891 178.734 170.887 179.008 171.875C179.581 173.867 180.202 175.859 180.847 177.842C181.493 179.826 182.162 181.818 182.888 183.785C183.251 184.772 183.606 185.76 183.977 186.739L184.42 187.919C184.509 188.123 184.626 188.312 184.767 188.48C185.842 189.611 187.153 190.47 188.599 190.991C190.373 191.721 192.198 192.309 194.059 192.749C195.979 193.209 197.955 193.586 199.955 193.912C201.956 194.238 203.988 194.498 205.972 194.749L206.045 198.976C201.726 199.424 197.374 199.405 193.059 198.917C190.798 198.68 188.562 198.229 186.38 197.57C185.2 197.204 184.05 196.737 182.944 196.172C181.672 195.531 180.507 194.683 179.492 193.661C178.905 193.056 178.395 192.376 177.976 191.635C177.863 191.443 177.766 191.234 177.661 191.024L177.468 190.606L177.113 189.861C176.645 188.865 176.177 187.861 175.742 186.848C174.862 184.823 174.015 182.789 173.249 180.705C172.483 178.621 171.749 176.52 171.096 174.369C170.781 173.289 170.483 172.209 170.2 171.105L169.789 169.431C169.66 168.845 169.555 168.343 169.426 167.64L177.508 166.058Z" fill="#FFC0AB"/>\n' +
    '    <path d="M174.935 157.16C178.161 157.997 180.452 171.883 180.452 171.883L171.507 178.194C171.507 178.194 162.925 164.208 165.425 160.508C168.055 156.65 170.192 155.913 174.935 157.16Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.5" d="M174.935 157.16C178.161 157.997 180.452 171.883 180.452 171.883L171.507 178.194C171.507 178.194 162.925 164.208 165.425 160.508C168.055 156.65 170.192 155.913 174.935 157.16Z" fill="#FAFAFA"/>\n' +
    '    <path d="M175.226 162.199L168.281 163.873L167.611 170.92C168.79 173.412 170.09 175.839 171.507 178.193L176.927 174.369C176.702 171.129 176.121 164.392 175.226 162.199Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.3" d="M175.226 162.199L168.281 163.873L167.611 170.92C168.79 173.412 170.09 175.839 171.507 178.193L176.927 174.369C176.702 171.129 176.121 164.392 175.226 162.199Z" fill="#FAFAFA"/>\n' +
    '    <path d="M204.828 194.674L210.417 188.815L211.151 197.093C211.151 197.093 206.957 200.03 204.061 198.448L204.828 194.674Z" fill="#FFC0AB"/>\n' +
    '    <path d="M216.982 187.919L217.232 194.883L211.151 197.076L210.417 188.798L216.982 187.919Z" fill="#FFC0AB"/>\n' +
    '    <path d="M174.935 157.161C174.935 157.161 178.686 158.533 171.168 206.492H139.179C139.719 192.983 139.736 184.647 133.533 156.926C138.001 155.967 142.529 155.346 147.084 155.068C151.917 154.708 156.769 154.708 161.602 155.068C167.861 155.662 174.935 157.161 174.935 157.161Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.5" d="M174.935 157.161C174.935 157.161 178.686 158.533 171.168 206.492H139.179C139.719 192.983 139.736 184.647 133.533 156.926C138.001 155.967 142.529 155.346 147.084 155.068C151.917 154.708 156.769 154.708 161.602 155.068C167.861 155.662 174.935 157.161 174.935 157.161Z" fill="#FAFAFA"/>\n' +
    '    <path d="M165.329 155.503C164.103 155.336 162.845 155.185 161.619 155.068C156.785 154.708 151.933 154.708 147.1 155.068C145.979 155.127 144.866 155.21 143.793 155.319L144.027 156.081C144.156 156.508 147.358 166.618 158.9 171.983L161.11 173.013L162.675 171.088C164.455 168.994 165.644 166.432 166.112 163.682C166.58 160.933 166.309 158.103 165.329 155.503Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.3" d="M165.329 155.503C164.103 155.336 162.845 155.185 161.619 155.068C156.785 154.708 151.933 154.708 147.1 155.068C145.979 155.127 144.866 155.21 143.793 155.319L144.027 156.081C144.156 156.508 147.358 166.618 158.9 171.983L161.11 173.013L162.675 171.088C164.455 168.994 165.644 166.432 166.112 163.682C166.58 160.933 166.309 158.103 165.329 155.503Z" fill="black"/>\n' +
    '    <path d="M141.034 169.799L136.904 173.44C137.792 178.294 138.38 182.337 138.759 185.927C139.534 182.32 141.494 172.553 141.034 169.799Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.3" d="M141.034 169.799L136.904 173.44C137.792 178.294 138.38 182.337 138.759 185.927C139.534 182.32 141.494 172.553 141.034 169.799Z" fill="#FAFAFA"/>\n' +
    '    <path d="M152.891 113.546C144.422 110.792 139.704 120.242 140.518 132.06C141.107 140.53 139.462 144.221 134.582 143.97C136.864 149.536 165.635 157.905 173.959 147.954C171.62 146.64 170.451 140.789 172.161 135.55C176.032 123.732 172.443 105.888 152.891 113.546Z" fill="#224665"/>\n' +
    '    <path d="M148.535 138.094C149.447 142.949 150.366 151.829 147.092 155.068C147.092 155.068 149.858 164.116 160.207 168.929C161.768 167.038 162.737 164.7 162.986 162.224C163.236 159.748 162.753 157.252 161.602 155.068C156.392 153.779 156.529 149.762 157.432 145.996L148.535 138.094Z" fill="#FFBDA8"/>\n' +
    '    <path d="M152.165 141.317L157.448 145.995C157.224 146.87 157.073 147.764 156.996 148.665C155.004 148.372 152.27 146.104 152.06 143.936C151.942 143.064 151.978 142.176 152.165 141.317Z" fill="#FFBDA8"/>\n' +
    '    <path d="M148.971 124.527C147.14 132.411 146.205 135.709 148.794 140.882C152.689 148.657 162.425 149.251 166.49 142.112C170.152 135.667 172.532 123.858 166.143 118.451C164.74 117.252 163.064 116.445 161.276 116.107C159.487 115.769 157.645 115.912 155.924 116.52C154.204 117.129 152.662 118.185 151.445 119.586C150.228 120.987 149.376 122.688 148.971 124.527Z" fill="#FFC0AB"/>\n' +
    '    <path d="M147.624 129.156C147.624 129.156 163.352 124.075 156.158 115.496C147.689 117.664 147.624 120.242 147.624 120.242V129.156Z" fill="#224665"/>\n' +
    '    <path d="M157.158 120.384C163.078 121.02 168.966 124.636 170.87 127.917C171.967 122.334 168.111 115.362 163.82 114.383C159.529 113.404 154.343 115.496 154.343 115.496L157.158 120.384Z" fill="#224665"/>\n' +
    '    <path d="M145.059 129.758C145.033 131.321 145.581 132.835 146.591 133.993C147.946 135.517 149.592 134.521 150.027 132.637C150.414 130.963 150.181 128.093 148.414 127.272C146.648 126.452 145.107 127.85 145.059 129.758Z" fill="#FFBDA8"/>\n' +
    '    <path d="M211.538 212.987C193.68 212.727 181.976 225.449 181.976 225.449C183.686 230.597 184.767 234.463 184.767 234.463C194.551 232.664 205.666 231.869 214.829 229.584C223.104 227.517 224.056 217.155 223.209 212.844C222.362 208.534 217.668 207.538 213.07 206.149C207.819 204.584 197.794 204.542 190.607 204.592C183.025 204.634 173.064 205.379 167.901 206.509C167.901 206.509 156.94 206.149 148.826 206.358C144.083 205.655 146.874 212.803 154.359 218.854C158.239 221.925 162.68 224.145 167.412 225.381C172.144 226.617 177.069 226.842 181.888 226.043H181.944" fill="#224665"/>\n' +
    '    <path d="M183.655 233.233L181.38 224.361C181.345 224.207 181.257 224.071 181.132 223.98C181.007 223.889 180.854 223.849 180.703 223.867L173.677 224.704C173.497 224.73 173.324 224.796 173.17 224.898C173.017 225 172.887 225.135 172.789 225.294C172.69 225.453 172.627 225.632 172.602 225.82C172.577 226.007 172.592 226.197 172.645 226.378C173.492 229.458 174.048 230.898 175.064 234.798C175.677 237.2 176.678 242.23 177.557 245.545C178.436 248.859 181.59 248.165 181.549 246.708C181.243 240.163 182.356 237.242 183.542 234.689C183.744 234.233 183.784 233.717 183.655 233.233Z" fill="#224665"/>\n' +
    '    <path d="M184.598 238.464C184.412 238.463 184.229 238.405 184.074 238.297C183.13 237.661 182.856 235.325 182.775 233.995C182.773 233.966 182.779 233.938 182.793 233.914C182.807 233.889 182.828 233.87 182.853 233.859C182.878 233.848 182.906 233.845 182.933 233.852C182.959 233.858 182.983 233.873 183.001 233.894C183.267 234.195 185.607 236.891 185.357 237.979C185.334 238.073 185.287 238.16 185.222 238.231C185.157 238.301 185.076 238.353 184.985 238.38C184.864 238.436 184.732 238.465 184.598 238.464ZM183.066 234.38C183.227 236.313 183.647 237.677 184.219 238.062C184.318 238.131 184.432 238.173 184.55 238.184C184.668 238.196 184.787 238.177 184.897 238.129C184.946 238.115 184.99 238.087 185.026 238.049C185.061 238.01 185.086 237.963 185.098 237.912C185.268 237.2 183.864 235.334 183.066 234.38Z" fill="#7C98B0"/>\n' +
    '    <path d="M183.678 234.806L180.726 223.315L184.614 221.649L190.091 233.986L183.678 234.806Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.6" d="M183.678 234.806L180.726 223.315L184.614 221.649L190.091 233.986L183.678 234.806Z" fill="#FAFAFA"/>\n' +
    '    <path d="M186.009 235.97C185.066 235.97 183.372 234.79 182.783 234.07C182.773 234.049 182.768 234.026 182.768 234.003C182.768 233.98 182.773 233.957 182.783 233.936C182.792 233.914 182.805 233.895 182.821 233.879C182.838 233.863 182.858 233.851 182.88 233.844C182.968 233.844 185.041 233.45 186.106 234.212C186.255 234.315 186.382 234.449 186.478 234.607C186.574 234.764 186.637 234.94 186.663 235.124C186.743 235.618 186.542 235.827 186.364 235.903C186.254 235.958 186.131 235.981 186.009 235.97ZM183.17 234.07C183.977 234.907 185.687 235.894 186.227 235.652C186.275 235.652 186.429 235.551 186.372 235.158C186.351 235.01 186.3 234.869 186.222 234.743C186.144 234.617 186.042 234.511 185.921 234.43C185.17 233.886 183.735 234.003 183.17 234.07Z" fill="#7C98B0"/>\n' +
    '    <path d="M145.301 206.935C135.372 205.613 120.684 205.102 119.845 215.841C119.006 226.579 126.145 237.602 145.559 238.196C164.974 238.79 169.168 233.727 169.862 229.408C170.749 223.951 172.281 222.561 167.643 220.092C163.006 217.623 162.151 209.17 145.301 206.935Z" fill="#224665"/>\n' +
    '    <path d="M166.305 235.066L164.022 226.194C163.978 226.042 163.99 225.878 164.056 225.735C164.121 225.591 164.236 225.477 164.377 225.416L170.886 222.537C171.052 222.471 171.229 222.441 171.406 222.451C171.584 222.461 171.757 222.509 171.915 222.592C172.074 222.676 172.213 222.793 172.325 222.937C172.436 223.08 172.518 223.246 172.564 223.424C173.306 226.537 173.515 228.077 174.516 231.978C175.129 234.38 176.645 239.276 177.5 242.591C178.355 245.905 175.233 246.96 174.596 245.654C171.725 239.795 169.329 237.87 167.103 236.255C166.712 235.967 166.429 235.547 166.305 235.066Z" fill="#224665"/>\n' +
    '    <path d="M167.506 240.238C167.432 240.24 167.358 240.225 167.29 240.195C167.222 240.164 167.161 240.119 167.111 240.063C166.369 239.226 167.111 235.71 167.191 235.309C167.197 235.28 167.21 235.254 167.231 235.235C167.251 235.215 167.277 235.203 167.304 235.2C167.331 235.196 167.358 235.201 167.381 235.215C167.405 235.228 167.423 235.25 167.433 235.275C168.006 236.472 168.885 238.623 168.369 239.678C168.297 239.827 168.192 239.955 168.061 240.051C167.93 240.148 167.778 240.209 167.619 240.23L167.506 240.238ZM167.369 235.761C167.135 237.008 166.812 239.343 167.296 239.87C167.331 239.91 167.376 239.94 167.425 239.956C167.475 239.973 167.528 239.975 167.579 239.962C167.697 239.946 167.809 239.901 167.906 239.829C168.003 239.758 168.082 239.663 168.135 239.552C168.563 238.69 167.796 236.732 167.369 235.744V235.761Z" fill="#7C98B0"/>\n' +
    '    <path d="M165.216 238.774C165.022 238.774 164.788 238.673 164.635 238.247C164.572 238.073 164.542 237.888 164.549 237.702C164.556 237.517 164.599 237.335 164.675 237.167C165.208 235.978 167.192 235.233 167.273 235.208C167.292 235.196 167.314 235.189 167.337 235.189C167.36 235.189 167.382 235.196 167.402 235.208C167.421 235.222 167.436 235.241 167.445 235.263C167.454 235.286 167.456 235.31 167.45 235.334C167.281 236.372 166.143 238.682 165.264 238.749L165.216 238.774ZM167.136 235.56C166.611 235.786 165.305 236.397 164.917 237.284C164.862 237.416 164.834 237.559 164.834 237.703C164.834 237.846 164.862 237.989 164.917 238.121C165.047 238.489 165.224 238.473 165.272 238.464C165.829 238.439 166.853 236.707 167.136 235.56Z" fill="#7C98B0"/>\n' +
    '    <path d="M166.667 234.957L164.812 224.193L160.279 224.018L160.626 237.719L166.667 234.957Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.6" d="M166.667 234.957L164.812 224.193L160.279 224.018L160.626 237.719L166.667 234.957Z" fill="#FAFAFA"/>\n' +
    '    <path d="M159.642 130.286C159.457 130.913 159.642 131.524 160.029 131.658C160.417 131.792 160.901 131.374 161.086 130.746C161.272 130.118 161.086 129.499 160.699 129.373C160.312 129.248 159.836 129.666 159.642 130.286Z" fill="#224665"/>\n' +
    '    <path d="M166.506 132.478C166.321 133.114 166.506 133.725 166.893 133.851C167.28 133.976 167.764 133.566 167.95 132.938C168.135 132.311 167.95 131.691 167.563 131.566C167.176 131.44 166.7 131.85 166.506 132.478Z" fill="#224665"/>\n' +
    '    <path d="M167.377 131.549H168.991C168.991 131.549 167.869 132.52 167.377 131.549Z" fill="#224665"/>\n' +
    '    <path d="M164.377 132.989C164.492 134.599 164.82 136.184 165.353 137.701C164.975 137.831 164.574 137.873 164.178 137.826C163.782 137.778 163.402 137.641 163.062 137.425L164.377 132.989Z" fill="#FFC0AB"/>\n' +
    '    <path d="M159.666 138.806C159.659 138.81 159.65 138.811 159.642 138.811C159.634 138.811 159.625 138.81 159.618 138.806C158.393 138.122 157.471 136.974 157.045 135.6C157.04 135.585 157.038 135.569 157.04 135.552C157.041 135.536 157.046 135.521 157.053 135.507C157.061 135.492 157.071 135.48 157.084 135.47C157.096 135.46 157.11 135.453 157.125 135.45C157.155 135.442 157.187 135.446 157.214 135.461C157.241 135.477 157.261 135.503 157.271 135.533C157.683 136.828 158.546 137.918 159.69 138.588C159.713 138.602 159.731 138.623 159.741 138.648C159.752 138.673 159.754 138.701 159.747 138.728C159.741 138.754 159.727 138.778 159.706 138.795C159.686 138.812 159.66 138.822 159.634 138.823L159.666 138.806Z" fill="#224665"/>\n' +
    '    <path d="M159.304 126.101C159.252 126.1 159.202 126.081 159.161 126.049C159.12 126.016 159.09 125.971 159.076 125.92C159.061 125.868 159.063 125.813 159.081 125.763C159.099 125.713 159.131 125.67 159.174 125.64C159.639 125.305 160.175 125.09 160.736 125.013C161.297 124.936 161.868 124.999 162.401 125.197C162.458 125.224 162.503 125.273 162.527 125.334C162.551 125.394 162.552 125.462 162.53 125.523C162.518 125.553 162.5 125.58 162.477 125.603C162.455 125.626 162.428 125.644 162.398 125.655C162.369 125.667 162.338 125.672 162.306 125.671C162.275 125.67 162.244 125.662 162.215 125.649C161.751 125.484 161.255 125.435 160.769 125.506C160.282 125.577 159.819 125.767 159.416 126.059C159.383 126.082 159.344 126.097 159.304 126.101Z" fill="#224665"/>\n' +
    '    <path d="M170.853 130.335C170.81 130.336 170.767 130.323 170.73 130.299C170.693 130.276 170.663 130.242 170.644 130.202C170.422 129.747 170.101 129.353 169.706 129.05C169.311 128.748 168.853 128.546 168.369 128.461C168.338 128.459 168.307 128.45 168.279 128.435C168.251 128.42 168.226 128.4 168.205 128.375C168.185 128.35 168.17 128.321 168.16 128.29C168.151 128.258 168.148 128.225 168.151 128.193C168.16 128.129 168.191 128.071 168.239 128.031C168.287 127.99 168.348 127.97 168.41 127.975C168.975 128.061 169.511 128.288 169.974 128.636C170.436 128.985 170.81 129.444 171.063 129.976C171.092 130.033 171.098 130.101 171.08 130.163C171.062 130.226 171.021 130.279 170.966 130.31C170.931 130.327 170.892 130.335 170.853 130.335Z" fill="#224665"/>\n' +
    '    <path d="M160.513 129.356H162.126C162.126 129.356 161.005 130.335 160.513 129.356Z" fill="#224665"/>\n' +
    '    <path d="M138.704 204.542L138.445 207.513C138.332 207.747 138.599 207.982 138.97 207.982H171.33C171.612 207.982 171.854 207.831 171.87 207.647L172.161 204.676C172.161 204.466 171.935 204.291 171.612 204.291H139.22C139.12 204.282 139.02 204.301 138.929 204.345C138.839 204.389 138.761 204.457 138.704 204.542Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.3" d="M138.704 204.542L138.445 207.513C138.332 207.747 138.599 207.982 138.97 207.982H171.33C171.612 207.982 171.854 207.831 171.87 207.647L172.161 204.676C172.161 204.466 171.935 204.291 171.612 204.291H139.22C139.12 204.282 139.02 204.301 138.929 204.345C138.839 204.389 138.761 204.457 138.704 204.542Z" fill="black"/>\n' +
    '    <path d="M143.051 208.3H142.188C142.011 208.3 141.881 208.208 141.89 208.099L142.301 204.232C142.301 204.123 142.462 204.031 142.632 204.031H143.503C143.672 204.031 143.809 204.123 143.793 204.232L143.39 208.099C143.374 208.208 143.228 208.3 143.051 208.3Z" fill="#224665"/>\n' +
    '    <path d="M168.861 208.3H168.055C167.877 208.3 167.748 208.208 167.764 208.099L168.168 204.232C168.168 204.123 168.329 204.031 168.506 204.031H169.369C169.547 204.031 169.676 204.123 169.66 204.232L169.256 208.099C169.216 208.208 169.063 208.3 168.861 208.3Z" fill="#224665"/>\n' +
    '    <path d="M155.956 208.3H155.085C154.916 208.3 154.779 208.208 154.795 208.099L155.198 204.232C155.198 204.123 155.368 204.031 155.537 204.031H156.408C156.578 204.031 156.707 204.123 156.699 204.232L156.287 208.099C156.295 208.208 156.142 208.3 155.956 208.3Z" fill="#224665"/>\n' +
    '    <path d="M228.484 183.55H172.346L164.207 217.95H220.346L228.484 183.55Z" fill="#FAFAFA"/>\n' +
    '    <path d="M200.044 211.539L165.708 219.85L143.672 213.439L143.898 211.539H200.044Z" fill="#FAFAFA"/>\n' +
    '    <path d="M138.292 168.786C137.614 171.063 136.888 173.431 136.243 175.775C135.598 178.118 134.912 180.454 134.323 182.797C133.735 185.141 133.17 187.476 132.71 189.769C132.5 190.907 132.307 192.046 132.178 193.117C132.077 193.871 132.028 194.632 132.033 195.393C132.22 196.008 132.547 196.568 132.984 197.026C133.638 197.765 134.365 198.43 135.154 199.009C136.939 200.347 138.827 201.528 140.8 202.541C144.995 204.676 149.304 206.563 153.706 208.191L152.73 212.292C147.831 211.457 143.02 210.137 138.364 208.35C135.925 207.425 133.573 206.268 131.339 204.893C130.112 204.117 128.963 203.214 127.911 202.198C126.581 200.947 125.575 199.37 124.983 197.611C124.814 197.067 124.697 196.506 124.636 195.938V195.502V195.184V194.607C124.636 193.853 124.636 193.134 124.636 192.431C124.709 191.024 124.854 189.694 125.04 188.388C125.402 185.76 125.846 183.232 126.451 180.738C127.056 178.244 127.613 175.775 128.282 173.331C128.951 170.887 129.661 168.485 130.484 166.041L138.292 168.786Z" fill="#FFC0AB"/>\n' +
    '    <path d="M133.509 156.926C130.282 157.939 125.443 170.987 125.443 170.987L137.872 180.194C137.872 180.194 143.462 169.037 142.115 164.735C140.671 160.274 137.171 155.771 133.509 156.926Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.5" d="M133.509 156.926C130.282 157.939 125.443 170.987 125.443 170.987L137.872 180.194C137.872 180.194 143.462 169.037 142.115 164.735C140.671 160.274 137.171 155.771 133.509 156.926Z" fill="#FAFAFA"/>\n' +
    '    <path d="M152.141 208.45L159.61 206.492L156.053 213.933C156.053 213.933 151.004 214.159 149.334 211.237L152.141 208.45Z" fill="#FFC0AB"/>\n' +
    '    <path d="M165.652 209.321L162.345 215.389L156.053 213.933L159.61 206.492L165.652 209.321Z" fill="#FFC0AB"/>\n' +
    '    <path d="M230.21 183.55H174.072L165.934 217.95H222.072L230.21 183.55Z" fill="#7C98B0"/>\n' +
    '    <path d="M222.071 217.95L221.846 219.85H165.707L165.933 217.95H222.071Z" fill="#7C98B0"/>\n' +
    '    <path d="M265.337 259.656H264.531V269.692H265.337V259.656Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.3" d="M265.337 259.656H264.531V269.692H265.337V259.656Z" fill="black"/>\n' +
    '    <path d="M265.337 273.55H264.531V277.115H265.337V273.55Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.3" d="M265.337 273.55H264.531V277.115H265.337V273.55Z" fill="black"/>\n' +
    '    <path d="M73.6843 202.525C73.2326 202.525 72.7809 202.525 72.3211 202.566C62.7389 203.278 54.9876 211.698 54.3826 222.059C54.2387 224.348 54.4484 226.645 55.0037 228.864C57.1146 237.777 58.1335 246.93 58.0365 256.107V282.891C58.017 283.8 58.3455 284.681 58.9502 285.339C59.5548 285.998 60.3864 286.382 61.2628 286.406H139.824V202.525H73.6843Z" fill="#7C98B0"/>\n' +
    '    <path opacity="0.3" d="M73.6843 202.525C73.2326 202.525 72.7809 202.525 72.3211 202.566C62.7389 203.278 54.9876 211.698 54.3826 222.059C54.2387 224.348 54.4484 226.645 55.0037 228.864C57.1146 237.777 58.1335 246.93 58.0365 256.107V282.891C58.017 283.8 58.3455 284.681 58.9502 285.339C59.5548 285.998 60.3864 286.382 61.2628 286.406H139.824V202.525H73.6843Z" fill="black"/>\n' +
    '    <path d="M159.182 223.432C159.182 211.405 149.778 201.737 138.477 202.574C128.895 203.286 121.144 211.706 120.539 222.068C120.395 224.356 120.605 226.653 121.16 228.872C123.271 237.785 124.29 246.938 124.193 256.116V282.899C124.173 283.808 124.502 284.689 125.106 285.348C125.711 286.006 126.543 286.39 127.419 286.414H152.222C153.097 286.388 153.928 286.004 154.532 285.345C155.136 284.687 155.465 283.808 155.448 282.899V254.542C155.376 245.945 156.382 237.375 158.44 229.048C158.925 227.218 159.174 225.33 159.182 223.432Z" fill="#7C98B0"/>\n' +
    "  </g>\n" +
    "  <defs>\n" +
    '    <clipPath id="clip0">\n' +
    '      <rect width="320" height="312.329" fill="white" transform="translate(0 -12)"/>\n' +
    "    </clipPath>\n" +
    "  </defs>"
];
